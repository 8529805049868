/* PublicProfile.css */

:root {
  --primary-color: #22577a;
  --verdigris: #38a3a5;
  --emerald: #57cc99;
  --light-green: #80ed99;
  --tea-green: #c7f9cc;
  --neutral: #ffffff;
  --text-muted: #666;
  --text-foreground: #333;
}

/* Container to wrap the entire profile */
.pp-container {
  max-width: 960px;
  margin: 0 auto;
  padding: 24px;
}

/* Card Styles */
.pp-card {
  background: var(--neutral);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-bottom: 24px; /* Spacing between cards */
}

.pp-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.pp-card-title {
  font-size: 1.5em;
  font-weight: bold;
  margin: 0;
}

/* We won't show an edit button on a public profile,
     but let's keep styling if you want something else. */
.pp-edit-btn {
  display: inline-flex;
  align-items: center;
  background: transparent;
  border: 1px solid var(--text-muted, #666);
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 0.9em;
  cursor: pointer;
}

.pp-card-content {
  padding: 16px;
}

.pp-content-row {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .pp-content-row {
    flex-direction: row;
  }
}

/* Avatar Section */
.pp-avatar-section {
  flex-shrink: 0;
  margin-bottom: 16px;
}

@media (min-width: 768px) {
  .pp-avatar-section {
    margin-right: 24px;
    margin-bottom: 0;
  }
}

.pp-avatar {
  position: relative;
  width: 128px;
  height: 128px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pp-avatar-placeholder {
  font-size: 48px;
  color: var(--text-muted);
}

.pp-avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

/* User Info */
.pp-user-info {
  flex-grow: 1;
}

.pp-user-name {
  font-size: 1.4em;
  font-weight: 600;
  margin: 0 0 8px 0;
}

.pp-user-bio {
  margin: 0 0 16px 0;
  color: var(--text-muted);
}

.pp-user-detail {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: var(--text-foreground);
}

.pp-detail-icon {
  margin-right: 8px;
  font-size: 1.2em;
}

.pp-section {
  margin-bottom: 16px;
}

.pp-section-title {
  font-weight: 600;
  margin-bottom: 8px;
}

/* Skills (Badges) */
.pp-skills {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.pp-badge {
  display: inline-block;
  background: var(--light-green);
  color: #000;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 0.85em;
}
