/* src/components/Hero.css */
:root {
  --lapis-lazuli: #22577a;
  --verdigris: #38a3a5;
  --emerald: #57cc99;
  --light-green: #80ed99;
  --tea-green: #c7f9cc;
  --neutral: #FFFFFF;
}

.hero {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 100px; /* Reduced top/bottom padding from 50px to 30px */
  margin: 20px 0;      /* Reduced top/bottom margin from 50px to 20px */
}

.hero-content {
  max-width: 50%;
  text-align: left;
  padding-left: 50px;
  padding-right: 50px;
}

.hero-content h1 {
  color: var(--lapis-lazuli);
  font-size: 3.25em;
  margin-bottom: 20px;
  margin-top: 0;       /* Ensure no unintended top margin */
}

.hero-content p {
  font-size: 1.25em;
  margin-bottom: 40px;
}

.hero-content .get-started {
  background-color: var(--emerald);
  padding: 15px 20px;
  border-radius: 5px;
  color: black;
  text-decoration: none;
  font-size: 1.2em;
  transition: background-color 0.3s;
  font-weight: 500;
}

.hero-content .get-started:hover {
  background-color: var(--verdigris);
}

.hero-image {
  max-width: 50%;
  padding-right: 50px;
  padding-left: 50px;
}

.hero-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    padding: 20px 20px; /* Reduced padding for smaller screens */
    margin: 20px 0;     /* Consistent margin on smaller screens */
  }

  .hero-content, .hero-image {
    max-width: 100%;
    text-align: center;
    padding: 20px;
  }

  .hero-content h1 {
    font-size: 2.5em;   /* Adjusted font size for better fit */
    margin-top: 10px;   /* Reduced top margin */
  }

  .hero-content p {
    font-size: 1.1em;
  }

  .hero-content .get-started {
    display: inline-block;
    margin-top: 20px;
    font-size: 1.1em;   /* Slightly reduced font size */
  }
}

@media (max-width: 480px) {
  .hero-content h1 {
    font-size: 2em;
  }

  .hero-content p {
    font-size: 1em;
  }

  .hero-content .get-started {
    font-size: 1em;
  }
}
