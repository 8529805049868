:root {
  --lapis-lazuli: #22577a;
  --verdigris: #38a3a5;
  --emerald: #57cc99;
  --light-green: #80ed99;
  --tea-green: #c7f9cc;
  --neutral: #FFFFFF;
}

body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  padding: 20px;
}

.register-box {
  display: flex;
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  max-width: 800px;
  width: 100%;
  margin: 20px;
}

.register-left,
.register-right {
  flex: 1;
  padding: 20px;
}

.register-left {
  border-right: 1px solid #ccc;
  text-align: left;
}

.register-left h2 {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 10px;
}

.register-left p {
  font-size: 1em;
  color: #666;
  margin-bottom: 20px;
}

.benefits-list {
  list-style: none;
  padding: 0;
}

.benefits-list li {
  font-size: 1em;
  color: #333;
  margin-bottom: 10px;
}

.register-right h2 {
  margin-bottom: 20px;
  font-size: 2em;
  color: #333;
}

.register-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* NEW: Styling for the select dropdowns */
.form-group select {
  width: 100%;
  padding: 10px;
  font-size: 1em; /* Increase if you want it even larger, e.g. 1.1em or 1.2em */
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  margin-top: 5px;
  margin-bottom: 10px;
}

/* NEW: Styling for the Short Bio textarea */
.form-group textarea#shortBio {
  width: 100%;
  padding: 10px;
  font-size: 1.25em; /* Bigger font for user input */
  border: 1px solid #ccc;
  border-radius: 5px;
  min-height: 100px; /* Increase if you'd like a taller box */
  resize: vertical;  /* Allow vertical resizing but keep horizontal fixed */
}

.register-button {
  background-color: var(--emerald);
  color: white;
  padding: 10px 20px;
  font-size: 1.15em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 550;
}

.register-button:hover {
  background-color: var(--verdigris);
}

.sign-in-link {
  margin-top: 20px;
  font-size: 0.9em;
}

.sign-in-link a {
  color: #007bff;
  text-decoration: none;
}

.sign-in-link a:hover {
  text-decoration: underline;
}

/* Message Styling */
.message {
  color: green;
  margin-bottom: 20px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .register-container {
    padding: 20px;
  }

  .register-box {
    flex-direction: column;
    padding: 20px;
    margin: 20px 10px;
  }

  .register-left,
  .register-right {
    padding: 10px;
    width: 100%;
  }

  .register-left {
    border-right: none;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
  }

  .register-left h2,
  .register-right h2 {
    font-size: 1.8em;
  }

  .form-group input,
  .form-group select,
  .form-group textarea#shortBio {
    padding: 10px;
    font-size: 1em;
  }

  .register-button {
    padding: 10px;
    font-size: 1em;
    width: 100%;
  }

  .sign-in-link {
    font-size: 0.9em;
  }
}
