/* src/components/Benefits.css */
:root {
  --primary-color: #003366;
  --secondary-color: #555555;
  --accent-color: #00AEEF;
  --neutral-light: #F5F5F5;
  --neutral-white: #FFFFFF;
}

.benefits-container {
  display: flex;
  flex-direction: column;
  padding: 50px 100px; /* Updated to match Hero's desktop padding */
  background-color: var(--neutral-light);
}

.benefit-row {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  gap: 25px;
}

.benefit-text {
  flex: 1;
  padding: 20px;
}

.benefit-text h2 {
  color: var(--primary-color);
  font-size: 2em;
  margin-bottom: 10px;
}

.benefit-text p {
  font-size: 1.1em;
  color: var(--secondary-color);
}

.benefit-image {
  flex: 1;
  padding: 20px;
}

.benefit-image img {
  width: 100%;
  border-radius: 10px;
}

.reverse {
  flex-direction: row-reverse;
}

/* Responsive Design */
@media (max-width: 768px) {
  .benefits-container {
    padding: 20px; /* Match Hero's tablet/mobile padding */
  }

  .benefit-row,
  .reverse {
    flex-direction: column;
    align-items: center;
  }

  .benefit-text,
  .benefit-image {
    padding: 10px 0;
    text-align: center;
  }

  .benefit-text h2 {
    font-size: 1.8em; /* Slight adjustment for better scaling */
  }

  .benefit-text p {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .benefits-container {
    padding: 20px; /* Ensure consistent padding on small screens */
  }

  .benefit-text h2 {
    font-size: 1.5em;
  }

  .benefit-text p {
    font-size: 0.95em;
  }
}
