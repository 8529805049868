:root {
  --lapis-lazuli: #22577a;
  --verdigris: #38a3a5;
  --emerald: #57cc99;
  --light-green: #80ed99;
  --tea-green: #c7f9cc;
  --neutral: #ffffff;
  --neutral-light: #f9f9f9;
  --text-dark: #333;
  --text-muted: #666;
}

/* Main Container */
.pitches-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  color: var(--text-dark);
  /* background-color: var(--neutral-light);  */
  min-height: 80vh; /* keeps layout consistent if there's little content */
}

.pitches-header {
  text-align: center;
  margin-bottom: 50px;
  margin-top: 50px;
}

.pitches-header h1,
.pitches-header h2 {
  margin-top: 0;
  font-size: 2em;
  color: var(--lapis-lazuli);
}

/* Filter Container (same structure as before) */
.filter-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-bottom: 30px;
}

.filter-container input,
.filter-container select {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  flex: 1;
  min-width: 150px;
  font-size: 1rem;
  color: var(--text-dark);
}

.filter-container input:focus,
.filter-container select:focus {
  outline: none;
  border-color: var(--lapis-lazuli);
}

.clear-filters-button {
  padding: 10px 20px;
  border-radius: 5px;
  background-color: var(--lapis-lazuli);
  border: none;
  cursor: pointer;
  color: var(--neutral);
  flex-shrink: 0;
  transition: background-color 0.2s ease;
}

.clear-filters-button:hover {
  background-color: var(--verdigris);
}

/* Responsive adjustments for the filter container */
@media (max-width: 768px) {
  .filter-container {
    flex-direction: column;
    align-items: stretch;
  }

  .filter-container input,
  .filter-container select,
  .clear-filters-button {
    width: 100%;
  }
}

/* Pitches Content & Grid */
.pitches-content {
  margin: 0 auto;
}

.pitches-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px; /* spacing between cards */
}

/* Pitch Card */
.pitch-card {
  width: 325px; /* match FeaturedPitches width */
  margin: 0 auto; /* center each card if needed */
  background-color: var(--neutral);
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.15s ease, box-shadow 0.15s ease;
  display: flex;
  flex-direction: column;
}

.pitch-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

/* Video or placeholder styling */
.pitch-card video {
  width: 100%;
  height: auto;
  display: block;
  background: #000; /* fallback if video not loaded */
}

.pitch-card p.no-video {
  margin: 0;
  padding: 10px;
  background-color: #f9f9f9;
  color: var(--text-muted);
  font-style: italic;
  border-bottom: 1px solid #ddd;
}

/* Pitch Info */
.pitch-info {
  padding: 0 20px 20px 20px;
  background-color: #f9f9f9; /* subtle background inside the card */
  border-top: 1px solid #ddd;
  text-align: left;
}

.pitch-info h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: var(--lapis-lazuli);
}

.pitch-info p {
  margin-bottom: 5px;
  color: var(--text-dark);
}

.pitch-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

/* Profile Button */
.view-profile-button {
  display: inline-block;
  padding: 8px 16px;
  background-color: var(--emerald);
  color: var(--neutral);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
  font-size: 0.9rem;
  transition: background-color 0.2s ease;
}

.view-profile-button:hover {
  background-color: var(--verdigris);
}

/* Favorite (Star) Button */
.favorite-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: var(--lapis-lazuli);
  cursor: pointer;
  transition: color 0.2s ease;
}

.favorite-btn.favorited {
  color: var(--verdigris);
}

.favorite-btn:hover {
  color: var(--emerald);
}

/* Pagination Styles */
.pagination {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 100px;
}

.pagination-button {
  padding: 8px 12px;
  margin: 0 3px;
  border: none;
  border-radius: 4px;
  background-color: var(--neutral);
  color: var(--text-dark);
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
  box-shadow: 0 1px 2px rgba(34, 87, 122, 0.1);
}

.pagination-button:hover {
  background-color: var(--lapis-lazuli);
  color: var(--neutral);
}

.pagination-button.active {
  background-color: var(--lapis-lazuli);
  color: var(--neutral);
}
