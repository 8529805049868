/* src/App.css */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

html, body, #root, .App {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  font-family: "Open Sans", sans-serif;
}

.App {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
}

/* footer {
  background-color: #f1f1f1;
  padding: 10px 0;
  text-align: center;
  width: 100%;
} */
