:root {
  --lapis-lazuli: #22577aff;
  --verdigris: #38a3a5ff;
  --emerald: #57cc99ff;
  --light-green: #80ed99ff;
  --tea-green: #c7f9ccff;
  --neutral: #FFFFFF;
}

/* Navbar.css */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 150px;
  background-color: var(--neutral);
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, .2);
}

.navbar-logo-image {
  height: 100px;
}

.navbar-menu {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
}

.navbar-menu li {
  margin-left: 25px;
  font-size: 1.15rem;
}

.navbar-menu a {
  color: black;
  text-decoration: none;
  font-size: 1em;
}

.navbar-get-started {
  padding: 10px 15px;
  border-radius: 5px;
  background-color: var(--emerald);
  color: var(--neutral) !important; /* Ensure the text is white */
  text-decoration: none;
  font-size: 1em; /* Ensure consistent font size */
  display: inline-block;
}

.navbar-get-started:hover {
  background-color: var(--verdigris);
}

.navbar-logout {
  padding: 10px 15px;
  border-radius: 5px;
  background-color: var(--emerald);
  color: var(--neutral) !important;
  text-decoration: none;
  font-size: 1em;
  display: inline-block;
  border: none;
  cursor: pointer;
  font-weight: 600;
}

.navbar-logout:hover {
  background-color: var(--verdigris);
  text-decoration: none;
}

@media (max-width: 1024px) {
  .navbar {
    padding: 0px 50px; /* Reduce padding for smaller screens */
  }

  .navbar-get-started {
    padding: 8px 12px; /* Adjust padding for smaller screens */
    font-size: 0.9em; /* Adjust font size for smaller screens */
  }
}

@media (max-width: 768px) {
  .navbar {
    padding: 0px 20px; /* Further reduce padding for smaller screens */
  }

  .navbar-menu {
    flex-direction: column;
    display: none;
    background-color: var(--verdigris);
    position: absolute;
    top: 80px;
    right: 20px;
    width: 180px;
    border-radius: 5px;
    padding: 5px 10px 10px 10px; /* Adjust padding for mobile menu */
  }

  .navbar-menu.show {
    display: flex;
  }

  .navbar-menu li {
    margin: 15px 0;
  }

  .navbar-get-started {
    padding: 10px 15px; /* Ensure consistent padding */
    font-size: 1em; /* Ensure consistent font size */
    text-align: center;
  }
}

/* Hamburger Icon */
.hamburger {
  display: none;
  cursor: pointer;
}

.hamburger div {
  width: 25px;
  height: 3px;
  background-color: var(--lapis-lazuli);
  margin: 5px;
}

@media (max-width: 768px) {
  .hamburger {
    display: block;
  }
}
