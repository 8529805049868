/* src/components/Contact.css */
:root {
  --lapis-lazuli: #22577a;
  --verdigris: #38a3a5;
  --emerald: #57cc99;
  --light-green: #80ed99;
  --tea-green: #c7f9cc;
  --neutral: #FFFFFF;
}

body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  padding: 20px;
}

.contact-box {
  display: flex;
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  max-width: 800px;
  width: 100%;
  margin: 10px;
}

.contact-left,
.contact-right {
  flex: 1;
  padding: 20px;
}

.contact-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Center-align content */
  text-align: center;   /* Center-align text */
  border-right: 1px solid #ccc;
}

.contact-left h1 {
  font-size: 2em;
  color: #333;
  margin-bottom: 10px;
}

.contact-left p {
  font-size: 1em;
  color: #666;
  margin-bottom: 20px;
}

.contact-right h2 {
  margin-bottom: 20px;
  font-size: 2em;
  color: #333;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contact-form .form-group textarea {
  height: 100px;
  resize: none;
}

.submit-button {
  background-color: var(--emerald);
  color: white;
  padding: 10px 20px;
  font-size: 1.1em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 150px;
}

.submit-button:hover {
  background-color: var(--verdigris);
}

@media (max-width: 768px) {
  .contact-container {
    padding: 20px;
  }

  .contact-box {
    flex-direction: column;
    padding: 20px;
    margin: 10px;
  }

  .contact-left,
  .contact-right {
    padding: 10px;
    width: 100%;
  }

  .contact-left {
    border-right: none;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
  }

  .contact-left h1,
  .contact-right h2 {
    font-size: 1.8em;
  }

  .form-group input,
  .form-group textarea {
    margin-left: 0;
    font-size: 1em;
  }

  .submit-button {
    padding: 10px 15px;
    font-size: 1em;
    width: 100%;
  }
}
