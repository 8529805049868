/* src/components/About.css */

/* CSS Variables */
:root {
    --lapis-lazuli: #22577a;   /* Primary color */
    --verdigris: #38a3a5;      /* Secondary color */
    --emerald: #57cc99;        /* Accent color */
    --light-green: #80ed99;    /* Light accent */
    --tea-green: #c7f9cc;      /* Background color */
    --neutral: #ffffff;        /* Neutral color (white) */
    --text-muted: #6c757d;     /* Muted text color */
    --background-color: var(--neutral);
    --primary-color: var(--lapis-lazuli);
  }
  
  /* Base container */
  .about-container {
    min-height: 100vh;
    background-color: var(--background-color);
  }
  
  /* Header styles */
  .about-header {
    padding: 1rem 0;
    color: var(--lapis-lazuli)
  }
  
  @media (min-width: 768px) {
    .about-header {
      padding: 2rem 0;
    }
  }
  
  .header-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
    text-align: center;
  }
  
  .header-content h1 {
    font-size: 1.875rem;
    font-weight: bold;
  }
  
  @media (min-width: 768px) {
    .header-content h1 {
      font-size: 3rem;
    }
  }
  
  /* Main content */
  .about-main {
    max-width: 1200px;
    margin: 0 auto;
    padding: 3rem 1rem;
  }
  
  /* Mission section */
  .mission-section {
    margin-bottom: 4rem;
    text-align: center;
  }
  
  .mission-section h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  
  @media (min-width: 768px) {
    .mission-section h2 {
      font-size: 2.25rem;
    }
  }
  
  .mission-section p {
    font-size: 1.125rem;
    color: var(--text-muted);
    max-width: 42rem;
    margin: 0 auto;
  }
  
  /* Features section */
  .features-section {
    margin-bottom: 10rem;
  }
  
  .features-section h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 2rem;
    text-align: center;
  }
  
  @media (min-width: 768px) {
    .features-section h2 {
      font-size: 2.25rem;
    }
  }
  
  .features-grid {
    display: grid;
    gap: 2rem;
  }
  
  @media (min-width: 768px) {
    .features-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  /* Card styles */
  .card {
    background-color: var(--neutral);
    border-radius: 0.5rem;
    padding: 1.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .card-header {
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .card-header .icon {
    width: 2.5rem;
    height: 2.5rem;
    margin-bottom: 0.5rem;
    color: var(--primary-color);
  }
  
  .card-header h3 {
    font-size: 1.25rem;
    font-weight: 600;
  }
  
  .card-content {
    color: var(--text-muted);
  }
  
  /* CTA section */
  .cta-section {
    text-align: center;
  }
  
  .cta-section h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  
  @media (min-width: 768px) {
    .cta-section h2 {
      font-size: 2.25rem;
    }
  }
  
  .cta-section p {
    font-size: 1.125rem;
    color: var(--text-muted);
    margin-bottom: 2rem;
    max-width: 42rem;
    margin-left: auto;
    margin-right: auto;
  }
  
  /* Button styles */
  .nav-btn {
    display: inline-block;
    padding: 15px 30px;
    font-size: 1.2em;
    background-color: var(--emerald);
    color: var(--neutral);
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
    border: none;
    cursor: pointer;
    font-weight: 500;
  }
  
  .nav-btn:hover {
    background-color: var(--verdigris);
  }
  
  @media (max-width: 1024px) {
    .nav-btn {
      padding: 12px 24px;
      font-size: 1.1em;
    }
  }
  