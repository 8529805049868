:root {
  --lapis-lazuli: #22577aff;
  --verdigris: #38a3a5ff;
  --emerald: #57cc99ff;
  --light-green: #80ed99ff;
  --tea-green: #c7f9ccff;
  --neutral: #FFFFFF;
}

.footer-container {
  background-color: var(--lapis-lazuli);
  color: var(--neutral);
  padding: 40px 150px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.newsletter-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.newsletter-row h3 {
  font-size: 1.5em; /* Reduced font size for better responsiveness */
}

.subscribe-form {
  display: flex;
  align-items: center;
  gap: 10px;
}

.subscribe-form input {
  padding: 10px 20px; /* Adjusted padding for better appearance */
  border: none;
  border-radius: 5px;
  width: 200px; /* Fixed width to control size */
  font-size: 1em;
}

.subscribe-form button {
  padding: 10px 20px;
  background-color: var(--emerald);
  color: var(--neutral);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

.subscribe-form button:hover {
  background-color: var(--verdigris);
}

.bottom-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px; /* Reduced gap for better alignment */
}

.left-info {
  font-size: 0.9em;
}

.social-icons {
  display: flex;
  gap: 10px;
}

.social-icons a {
  color: var(--neutral);
}

.right-info {
  display: flex;
  gap: 20px;
}

.right-info a {
  color: var(--neutral);
  text-decoration: none;
  font-size: .90em;
}

.right-info a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .footer-container {
    padding: 20px;
  }

  .newsletter-row {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px; /* Reduced gap for smaller screens */
  }

  .subscribe-form {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px; /* Added gap for better spacing */
  }

  .subscribe-form input {
    width: 100%; /* Full width on smaller screens */
    margin-bottom: 10px;
  }

  .bottom-row {
    flex-direction: column;
    gap: 10px;
  }

  .social-icons {
    flex-direction: row; /* Ensure icons stay horizontal */
    gap: 10px;
  }

  .right-info {
    flex-direction: row; /* Keep links horizontal */
    gap: 10px;
  }
}
