:root {
  --lapis-lazuli: #22577aff;
  --verdigris: #38a3a5ff;
  --emerald: #57cc99ff;
  --light-green: #80ed99ff;
  --tea-green: #c7f9ccff;
  --neutral: #FFFFFF;
}

.get-started-bottom-container {
    text-align: center;
    margin-bottom: 100px;
}

.get-started-bottom {
  padding: 10px 15px;
  border-radius: 5px;
  background-color: var(--emerald);
  color: black; 
  text-decoration: none;
  font-size: 1em; 
  display: inline-block; 
  border-style: none;
}

.get-started-bottom:hover {
  background-color: var(--verdigris);
}
