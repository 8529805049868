.separator {
  text-align: center;
  padding: 30px 20px;
  background-color: var(--neutral-light);
}

.separator h2 {
  font-size: 2.5em;
  color: var(--primary-color);
  margin-bottom: 20px;
}

.separator p {
  font-size: 1.2em;
  color: var(--secondary-color);
}
