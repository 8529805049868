:root {
  --primary-color: #22577a;
  --verdigris: #38a3a5;
  --emerald: #57cc99;
  --light-green: #80ed99;
  --tea-green: #c7f9cc;
  --neutral: #FFFFFF;
  --text-muted: #666;
  --text-foreground: #333;
}

/* Container */
.jsd-container {
  max-width: 960px;
  margin: 0 auto;
  padding: 24px;
}

/* Card Styles */
.jsd-card {
  background: var(--neutral);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  overflow: hidden;
}

.jsd-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.jsd-card-title {
  font-size: 1.5em;
  font-weight: bold;
  margin: 0;
}

.jsd-edit-btn {
  display: inline-flex;
  align-items: center;
  background: transparent;
  border: 1px solid var(--text-muted, #666);
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 0.9em;
  cursor: pointer;
}

.jsd-edit-btn:hover {
  border-color: var(--primary-color);
}

.jsd-edit-icon {
  margin-right: 4px;
}

.jsd-card-content {
  padding: 16px;
}

.jsd-content-row {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .jsd-content-row {
    flex-direction: row;
  }
}

/* Avatar Section */
.jsd-avatar-section {
  flex-shrink: 0;
  margin-bottom: 16px;
}

@media (min-width: 768px) {
  .jsd-avatar-section {
    margin-right: 24px;
    margin-bottom: 0;
  }
}

.jsd-avatar {
  position: relative;
  width: 128px;
  height: 128px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
  background: #f0f0f0;
  /* Center the placeholder icon if there's no image */
  display: flex;
  align-items: center;
  justify-content: center;
}

.jsd-avatar-placeholder {
  font-size: 48px;
  color: var(--text-muted);
}

.jsd-avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

/* Small Camera Upload Icon */
.jsd-avatar-upload {
  position: absolute;
  bottom: 0;
  right: 0;
  background: rgba(0,0,0,0.6);
  color: #fff;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  transform: translate(50%, 50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.jsd-avatar-upload:hover {
  background: rgba(0,0,0,0.8);
}

.jsd-avatar-upload input {
  display: none;
}

/* User Info */
.jsd-user-info {
  flex-grow: 1;
}

.jsd-user-name {
  font-size: 1.4em;
  font-weight: 600;
  margin: 0 0 8px 0;
}

.jsd-user-bio {
  margin: 0 0 16px 0;
  color: var(--text-muted);
}

.jsd-user-detail {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: var(--text-foreground);
}

.jsd-detail-icon {
  margin-right: 8px;
  font-size: 1.2em;
}

.jsd-section {
  margin-bottom: 16px;
}

.jsd-section-title {
  font-weight: 600;
  margin-bottom: 8px;
}

/* Skills (Badges) */
.jsd-skills {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.jsd-badge {
  display: inline-block;
  background: var(--light-green);
  color: #000;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 0.85em;
}

.jsd-pitch-card {
  margin-top: 24px;
}

.jsd-pitch-video {
  width: 100%;
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 16px;
}

.jsd-pitch-upload-section {
  margin-top: 16px;
}

/* Responsive Adjustments */
@media (max-width: 767px) {
  .jsd-avatar {
    margin-bottom: 16px;
  }
}
